import React from 'react'
import {
  Box,
  Grid,
  IconButton,
  styled,
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  Link,
  Theme
} from '@hermes/web-components'
import { FooterSocialTypes, SocialListProps } from '../../types'
import { TEST_IDS } from '../../__tests__/testUtils/constants'

const iconStyle = (theme: Theme) => ({
  width: '1rem',
  height: '1rem',
  [theme.breakpoints.down('md')]: {
    height: '0.8rem',
    width: '0.8rem'
  }
})

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '10%',
  backgroundColor: theme.palette.background.dark,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary
  },
  height: 33,
  width: 33,
  [theme.breakpoints.down('md')]: {
    height: 22,
    width: 22
  }
}))

const getSocialIcon = (type: FooterSocialTypes) => {
  switch (type) {
    case FooterSocialTypes.TWITTER:
      return <TwitterIcon sx={iconStyle} />

    case FooterSocialTypes.FACEBOOK:
      return <FacebookIcon sx={iconStyle} />

    case FooterSocialTypes.INSTAGRAM:
      return <InstagramIcon sx={iconStyle} />

    case FooterSocialTypes.LINKEDIN:
      return <LinkedInIcon sx={iconStyle} />

    default:
      return <></>
  }
}

interface Props {
  lang: string
  socialList?: SocialListProps[]
}

const Socials = ({ lang, socialList = [] }: Props) => (
  <Box>
    <Grid container spacing={1}>
      {socialList.map(({ href, type }) => (
        <Grid item key={`grid-item-${type}`}>
          <Link href={href[lang]} target="_blank">
            <StyledIconButton aria-label={`${type} icon`} data-testid={TEST_IDS.FOOTER_SOCIAL_BUTTON}>
              {getSocialIcon(type)}
            </StyledIconButton>
          </Link>
        </Grid>
      ))}
    </Grid>
  </Box>
)

export default Socials
