import React, { PropsWithChildren, useContext } from 'react'
import { AppDataContext } from '../providers/AppData'
import Header from './Header'
import Footer from './Footer'
import { useRouter } from 'next/router'

function App({ children }: PropsWithChildren<object>) {
  const router = useRouter()
  const { tenantSettings, locale, language } = useContext(AppDataContext)

  return (
    <>
      <Header />
      {children}
      <Footer
        locale={locale}
        lang={language}
        footerColumnLinks={tenantSettings?.footerColumnLinks}
        footerSocialLinks={tenantSettings?.footerSocialLinks}
        pathname={router.pathname}
      />
    </>
  )
}

export default App
