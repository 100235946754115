import { Link, MenuItem, styledComponent } from '@hermes/web-components'

export const StyledLink = styledComponent(Link, {
  shouldForwardProp: (prop) => prop !== 'isIconed'
})<{ isIconed?: boolean }>(({ theme, isIconed }) => ({
  textDecoration: 'none',
  color: 'inherit',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: isIconed ? 'flex-start' : 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.75, 2)
}))

export const StyledMenuItem = styledComponent(MenuItem, {
  shouldForwardProp: (prop: string) => !['active'].includes(prop)
})<{ active?: boolean }>(({ theme, active }) => ({
  '&:hover': {
    backgroundColor: theme.palette.secondary.light
  },
  background: active ? theme.palette.background.default : theme.palette.common.white,
  fontSize: `${theme.typography.body2.fontSize} !important`,
  color: theme.palette.text.primary,
  justifyContent: 'space-between',
  spacing: theme.spacing(1.75, 2),
  minWidth: 200,
  position: 'relative',
  padding: 0,
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    display: 'block'
  }
}))
