export default function getCountryByLocale(locale: string) {
  switch (locale) {
    case 'uk':
    case 'wpa':
      return 'united-kingdom'
    case 'en-ae':
      return 'united-arab-emirates'
    case 'ar-ae':
      return 'united-arab-emirates'
    case 'de-at':
      return 'österreich'
    case 'de':
      return 'deutschland'
    case 'au':
      return 'australia'
    case 'ie':
      return 'ireland'
    default:
      return 'united-kingdom'
  }
}
