import React, { useMemo } from 'react'
import { Box, Container, Grid, List, ListItem, Typography, LogoIcon, GoogleRecaptchaLogo } from '@hermes/web-components'
import useTranslateMessage from '../../hooks/useTranslateMessage'
import Socials from './Socials'
import { StyledBox, StyledInfoBox, StyledLink, GoogleLogoWrapper } from './styles'
import { FooterLinkProps, FooterLinksListProps, SocialListProps } from '../../types'
import { TEST_IDS } from '../../__tests__/testUtils/constants'

type Props = {
  locale: string
  lang: string
  footerSocialLinks?: SocialListProps[]
  footerColumnLinks?: FooterLinksListProps[]
  defaultHost?: string
  pathname?: string
}

type FooterItemProps = { children: [JSX.Element, JSX.Element] }

const displayCaptchaLogoPaths = ['/specialist/[slug]', '/practice/[slug]', '/find/[...search]']

const FooterGridItem = ({ children }: FooterItemProps) => {
  const [HeaderItem, BottomItem] = children

  return (
    <Grid item xs={12} sm={3} mb={3}>
      <Box pb={1}>{HeaderItem}</Box>
      <Box pt={1}>{BottomItem}</Box>
    </Grid>
  )
}

const openCookieBanner = () => {
  // allow underscore usage
  // eslint-disable-next-line no-underscore-dangle
  window.__tcfapi('displayConsentUi', 2, () => {})
}

const Footer = ({ defaultHost, locale, lang, footerColumnLinks, footerSocialLinks, pathname = '' }: Props) => {
  const translate = useTranslateMessage()

  const date = new Date()
  const currentYear = date.getFullYear()

  const FooterListItem = ({ href, title, external, defaultLocale, isCookieBanner }: FooterLinkProps) => {
    const link = useMemo(
      () => (external ? href : `${defaultHost || ''}/${defaultLocale}${href}`),
      [defaultLocale, external, href]
    )

    const onClickOrHref = useMemo(
      () =>
        isCookieBanner
          ? { onClick: openCookieBanner, component: 'button' }
          : { href: link, target: external ? '_blank' : '_self' },
      [external, isCookieBanner, link]
    )

    return (
      <ListItem sx={{ pl: 0 }}>
        <StyledLink draggable={false} {...onClickOrHref} underline="none" variant="body2">
          {title[lang]}
        </StyledLink>
      </ListItem>
    )
  }

  return (
    <footer style={{ contentVisibility: 'auto', containIntrinsicHeight: '600px' }} data-testid={TEST_IDS.FOOTER}>
      {displayCaptchaLogoPaths.includes(pathname) && (
        <GoogleLogoWrapper>
          <GoogleRecaptchaLogo />
        </GoogleLogoWrapper>
      )}
      <StyledBox p={{ xs: 2 }} py={{ xs: 4, sm: 5 }}>
        <Container>
          <Grid container spacing={{ xs: 3 }}>
            <FooterGridItem data-testid={TEST_IDS.FOOTER_LOGO_AND_CONTACTS}>
              <LogoIcon
                data-testid={TEST_IDS.FOOTER_LOGO}
                sx={{ width: { sm: 120, xs: 115 }, height: { sm: 32, xs: 30 } }}
                lettersColor="white"
              />
              <Socials data-testid={TEST_IDS.FOOTER_SOCIALS} socialList={footerSocialLinks} lang={lang} />
            </FooterGridItem>
            {footerColumnLinks?.map(({ name, links }) => (
              <FooterGridItem key={`grid-item-${name[lang]?.trim()}`}>
                <Typography data-testid={TEST_IDS.FOOTER_LINK_NAME} variant="body1" color="text.light">
                  {name[lang]}
                </Typography>
                <List dense={true} sx={{ border: 0, background: 'none' }}>
                  {links.map((item) => (
                    <FooterListItem
                      data-testid={TEST_IDS.FOOTER_LINK_ITEM}
                      defaultLocale={
                        (name[lang] === 'Learn about Doctify' || name[lang] === 'Getting Started') && locale === 'ar-ae'
                          ? 'en-ae'
                          : locale
                      }
                      key={`list-${name[lang]?.trim()}-item-${item.title[lang]?.trim()}`}
                      {...item}
                    />
                  ))}
                </List>
              </FooterGridItem>
            ))}
          </Grid>
        </Container>
      </StyledBox>
      <StyledInfoBox py={{ xs: 3, sm: 2 }} pb={{ xs: 10 }} data-testid={TEST_IDS.FOOTER_BOTTOM}>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: 'space-between' },
            flexDirection: {
              xs: 'column',
              sm: 'row'
            }
          }}
        >
          <Typography variant="body2" mb={{ xs: 1, sm: 0 }} data-testid={TEST_IDS.FOOTER_BOTTOM_RIGHTS}>
            {translate('footer.rights', { currentYear })}
          </Typography>
        </Container>
      </StyledInfoBox>
    </footer>
  )
}

export default Footer
