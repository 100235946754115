import React, { useEffect, useMemo } from 'react'
import type { AppProps, AppContext } from 'next/app'
import { IntlProvider, ReactIntlErrorCode } from 'react-intl'
import {
  ThemeProvider,
  CssBaseline,
  StyledEngineProvider,
  GlobalStyles,
  createCache,
  CacheProvider,
  Box,
  StarIcon,
  EmptyStarIcon,
  UserVerifiedIcon,
  ConnectionsBadgeIcon,
  PersonWithShieldIcon,
  CheckboxCircleOutlineIcon,
  LocationOnIcon,
  UnverifiedIcon,
  CookieBanner,
  GoogleTagManager,
  GoogleTagManagerNoScript
} from '@hermes/web-components'
import dayjs from 'dayjs'
import { prefixer } from 'stylis'
import rtlPlugin from 'stylis-plugin-rtl'
import { useRouter } from 'next/router'
import Head from 'next/head'
import App from '../components/App'
import getLanguageFromLocale from '../utils/getLanguageFromLocale'
import { getLocaleTranslations } from '../api/translations'
import { getTenantSettings } from '../api/settings'
import getTheme from '../components/customTheme'
import { AppDataProvider } from '../providers/AppData'
import { TENANTS, RTL_TENANTS, MUI_LTR, MUI_RTL, GTM_ID, WPA_HEADER_GTM_ID, WPA_BODY_GTM_ID } from '../constants/global'
import { TenantSettings } from '../types/tenantSettings'

import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/en-au'
import 'dayjs/locale/ar'
import TickIcon from '../components/Icons/TickIcon'
import '../styles/global.scss'
import handleTenantRedirect from '../utils/handleTenantRedirect'

function MyApp(props: AppProps) {
  // @ts-ignore
  const { Component, pageProps, translations, language, tenantSettings } = props
  const router = useRouter()
  const locale = router.locale || router.defaultLocale!
  const isWPA = locale === TENANTS.WPA
  const gtmId = isWPA ? WPA_HEADER_GTM_ID : GTM_ID

  dayjs.locale(language)

  const isRtl = useMemo(() => RTL_TENANTS.includes(locale), [locale])

  const cacheRtl = createCache({
    key: MUI_RTL,
    stylisPlugins: [prefixer, rtlPlugin],
    speedy: true
  })

  const cacheLtr = createCache({
    key: MUI_LTR,
    stylisPlugins: [prefixer],
    speedy: true
  })
  // todo: clarify deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cache = useMemo(() => (isRtl ? cacheRtl : cacheLtr), [isRtl])

  const theme = getTheme(RTL_TENANTS.includes(locale))

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={cache}>
        <CookieBanner />
        <GoogleTagManager gtmId={gtmId} />
        {isWPA ? <GoogleTagManagerNoScript gtmId={WPA_BODY_GTM_ID} /> : null}
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, minimal-ui"
          />
        </Head>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles
            styles={{
              html: {
                scrollBehavior: 'smooth',
                scrollPaddingTop: 110,
                [theme.breakpoints.down('sm')]: {
                  scrollPaddingTop: 96
                }
              }
            }}
          />
          <AppDataProvider tenantSettings={tenantSettings as TenantSettings} locale={locale} language={language}>
            <IntlProvider
              messages={translations}
              // Cannot be moved to const because the FormatError type is not exported from react-intl
              onError={(error) => {
                if (error.code === ReactIntlErrorCode.FORMAT_ERROR) {
                  return console.warn(`FORMAT_ERROR in ${error?.descriptor?.id}`)
                }

                console.warn(error)
              }}
              locale={language}
            >
              <App>
                <Component {...pageProps} />
                <Box id="icons" sx={{ position: 'fixed', opacity: 0, pointerEvents: 'none' }}>
                  <StarIcon id="rating-star-icon" />
                  <EmptyStarIcon id="rating-empty-star-icon" />
                  <UserVerifiedIcon id="user-verified-icon" />
                  <ConnectionsBadgeIcon id="connections-badge-icon" />
                  <PersonWithShieldIcon id="person-with-shield-icon" />
                  <CheckboxCircleOutlineIcon id="checkbox-circle-outline-icon" />
                  <LocationOnIcon id="location-on-icon" />
                  <UnverifiedIcon id="unverified-icon" />
                  <TickIcon id="tick-icon" />
                </Box>
              </App>
            </IntlProvider>
          </AppDataProvider>
        </ThemeProvider>
      </CacheProvider>
    </StyledEngineProvider>
  )
}

MyApp.getInitialProps = async ({ ctx, Component }: AppContext) => {
  handleTenantRedirect(ctx)

  const pageProps = (await Component.getInitialProps?.(ctx)) || {}
  const locale = ctx.locale || ctx.defaultLocale || ''
  const language = getLanguageFromLocale(locale)
  const [translations, tenantSettings] = await Promise.all([getLocaleTranslations(language), getTenantSettings(locale)])

  return {
    pageProps,
    translations,
    language,
    tenantSettings
  }
}

export default MyApp
