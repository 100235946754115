import { NextPageContext } from 'next/dist/shared/lib/utils'

const redirectsMap = {
  AE: '/en-ae',
  AT: '/de-at',
  DE: '/de',
  AU: '/au',
  IE: '/ie',
  SA: '/en-sa'
}

// eslint-disable-next-line consistent-return
const handleTenantRedirect = ({ req, res, locale }: NextPageContext) => {
  if (req && res && locale === 'catchAll' && (req.url === '' || req.url === '/')) {
    const country = req.headers['cloudfront-viewer-country']
    const destination = redirectsMap[country as keyof typeof redirectsMap] || '/uk'
    res.writeHead(301, { Location: destination })
    res.end()
    return {}
  }
}

export default handleTenantRedirect
